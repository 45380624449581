.accordion {
	&-item {
		margin-bottom: rem(20px);
		[class^='icon-']:before,
		[class*=' icon-']:before {
			width: rem(24px);
			height: rem(24px);
			color: #fff;
			background-color: #294264;
			border-radius: 50%;
			font-size: rem(16px);
			line-height: rem(24px);
			font-weight: 300;
				transition: all .3s;
		}
		*[aria-expanded='false'].collapsed {
			i.icon-plus {
				display: block;
			}
			i.icon-cancel {
				display: none;
			}
		}
		*[aria-expanded='true']:not(.collapsed) {
			i.icon-plus {
				display: none;
			}
			i.icon-cancel {
				display: block;
			}
		}
		&-title {
			@include mavenpro_bold;
			font-size: rem(17px);
			line-height: rem(18.7px);
			color: #294264;
			display: flex;
			justify-content: space-between;
			padding-bottom: rem(20px);
				transition: all .3s;
			&[aria-expanded='false'].collapsed {
				border-bottom: solid 1px #e1e5ed;
			}
			&:hover {
				text-decoration: none;
				* {
					color: #6ac6f7;
				}
				[class^='icon-']:before,
				[class*=' icon-']:before {
					background-color: #6ac6f7;
				}
			}
			> span {
				display: block;
			}
		}
		&-body {
			color: #1c1c1c;
			font-size: rem(14px);
			line-height: rem(14 * 1.1px);
			padding-bottom: rem(20px);
			table {
				tr {
					th,
					td {
						vertical-align: middle;
						border-bottom: rem(8px) #fff solid;
					}
					span {
						position: relative;
					}
					th {
						color: #fff;
						position: relative;
						&:first-child:before {
							border-radius: rem(8px) 0 0 rem(8px);
						}
						&:last-child:before {
							border-radius: 0 rem(8px) rem(8px) 0;
						}
						&:before {
							left: 0;
							top: 0;
							position: absolute;
							content: ' ';
							display: block;
							width: 100%;
							height: 100%;
							background-color: #294264;
						}
					}

					&:nth-child(even) td:not(.no-color):before {
						background-color: #f9f9f9;
					}
					&:nth-child(odd) td:not(.no-color):before {
						background-color: #f1f1f1;
					}

					td {
						position: relative;
						&:last-child {
							color: #7b7b7b;
						}
						&:first-child:before {
							border-radius: rem(8px) 0 0 rem(8px);
						}
						&:last-child:before {
							border-radius: 0 rem(8px) rem(8px) 0;
						}
						&:before {
							left: 0;
							top: 0;
							position: absolute;
							content: ' ';
							display: block;
							width: 100%;
							height: 100%;
						}
					}
				}
			}
			p {
				color: #747474;
				margin: 0;
			}
		}
	}
}

@font-face {
    font-family: 'Maven Pro';
    src: url('MavenPro-Bold.eot');
    src: local('Maven Pro Bold'), local('MavenPro-Bold'),
        url('MavenPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('MavenPro-Bold.woff2') format('woff2'),
        url('MavenPro-Bold.woff') format('woff'),
        url('MavenPro-Bold.ttf') format('truetype'),
        url('MavenPro-Bold.svg#MavenPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Maven Pro';
    src: url('MavenPro-Medium.eot');
    src: local('Maven Pro Medium'), local('MavenPro-Medium'),
        url('MavenPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('MavenPro-Medium.woff2') format('woff2'),
        url('MavenPro-Medium.woff') format('woff'),
        url('MavenPro-Medium.ttf') format('truetype'),
        url('MavenPro-Medium.svg#MavenPro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Maven Pro';
    src: url('MavenPro-Regular.eot');
    src: local('Maven Pro Regular'), local('MavenPro-Regular'),
        url('MavenPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('MavenPro-Regular.woff2') format('woff2'),
        url('MavenPro-Regular.woff') format('woff'),
        url('MavenPro-Regular.ttf') format('truetype'),
        url('MavenPro-Regular.svg#MavenPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Breno';
    src: url('Breno-Medium.eot');
    src: local('Breno Medium'), local('Breno-Medium'),
        url('Breno-Medium.eot?#iefix') format('embedded-opentype'),
        url('Breno-Medium.woff2') format('woff2'),
        url('Breno-Medium.woff') format('woff'),
        url('Breno-Medium.ttf') format('truetype'),
        url('Breno-Medium.svg#Breno-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Breno';
    src: url('Breno-MediumItalic.eot');
    src: local('Breno Medium Italic'), local('Breno-MediumItalic'),
        url('Breno-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Breno-MediumItalic.woff2') format('woff2'),
        url('Breno-MediumItalic.woff') format('woff'),
        url('Breno-MediumItalic.ttf') format('truetype'),
        url('Breno-MediumItalic.svg#Breno-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Breno';
    src: url('Breno-Light.eot');
    src: local('Breno Light'), local('Breno-Light'),
        url('Breno-Light.eot?#iefix') format('embedded-opentype'),
        url('Breno-Light.woff2') format('woff2'),
        url('Breno-Light.woff') format('woff'),
        url('Breno-Light.ttf') format('truetype'),
        url('Breno-Light.svg#Breno-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Breno';
    src: url('Breno-Black.eot');
    src: local('Breno Black'), local('Breno-Black'),
        url('Breno-Black.eot?#iefix') format('embedded-opentype'),
        url('Breno-Black.woff2') format('woff2'),
        url('Breno-Black.woff') format('woff'),
        url('Breno-Black.ttf') format('truetype'),
        url('Breno-Black.svg#Breno-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Breno';
    src: url('Breno-Bold.eot');
    src: local('Breno Bold'), local('Breno-Bold'),
        url('Breno-Bold.eot?#iefix') format('embedded-opentype'),
        url('Breno-Bold.woff2') format('woff2'),
        url('Breno-Bold.woff') format('woff'),
        url('Breno-Bold.ttf') format('truetype'),
        url('Breno-Bold.svg#Breno-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Breno';
    src: url('Breno-Book.eot');
    src: local('Breno Book'), local('Breno-Book'),
        url('Breno-Book.eot?#iefix') format('embedded-opentype'),
        url('Breno-Book.woff2') format('woff2'),
        url('Breno-Book.woff') format('woff'),
        url('Breno-Book.ttf') format('truetype'),
        url('Breno-Book.svg#Breno-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}


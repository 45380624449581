.navbar {
	background-color: #fff;
	@include mavenpro_bold;
	.nav-link {
		color: #294264;
		line-height: rem(16px);
		position: relative;
		letter-spacing: rem(0.4px);
		&:not(.nav-link-icon) {
			font-size: rem(12px);
			padding-left: rem(8px);
			padding-right: rem(8px);
			&:after {
				content: ' ';
				background-color: #294264;
				position: absolute;
				left: 0;
				width: 100%;
				height: rem(5px);
				top: rem(40px);
				display: none;
				opacity: 0;
				transition: all .3s;
			}
			transition: all .3s;
			@include media-breakpoint-up(md) {
				&:hover {
					color: #6ac6f7;
					&:after {
						background-color: #6ac6f7;
						opacity: 1;
					}
				}
				&:after {
					display: block;
				}
			}
			@include media-breakpoint-up(lg) {
				padding-left: rem(16px);
				padding-right: rem(16px);
				&:after {
					top: rem(57px);
				}
			}
		}
		&-icon {
			font-size: rem(16px);
			color: #6ac6f7;
				transition: all .3s;
			&:hover {
				color: #294264;
			}
		}
	}
	&-toggler {
		color: #294264;
	}
	.navbar-toggler-icon {
		background-image: escape-svg($navbar-light-toggler-icon-bg);
	}
}

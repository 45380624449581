.home {
	&-slider {
		&-dot {
			opacity: 1 !important;
			background-color: #294264 !important;
			&.active {
				background-color: #6ac6f7 !important;
			}
		}
	}
	&-slide {
		&-title {
			@include mavenpro;
			position: relative;
			width: rem(300px);
			max-width: 100%;
			color: #fff;
			padding-left: rem(20px);
			font-size: rem(26px);
			text-shadow: 0px 0px 16px #212121;
			&:after {
				display: block;
				background-color: #6ac6f7;
				content: '';
				position: absolute;
				width: rem(4px);
				top: 0;
				border-radius: rem(2px);
				height: 100%;
				left: rem(-8px);
			}
		}
	}
	&-content {
		background-color: #294264;
		box-shadow: inset -50vh 0px 0px 0px rgba(106, 198, 247, 1);
		@include breno;
	}
	&-href {
		font-size: rem(12px);
		letter-spacing: rem(0.8px);
		position: relative;
		// top: rem(-8px);
		&,
		&:hover {
			color: #6ac6f7;
		}
	}
	&-link &-href > i {
		color: #6ac6f7;
	}
	&-link {
		text-align: center;
		background-color: #294264;
		display: flex;
		padding-bottom: rem(8px);
		padding-top: rem(8px);
		justify-content: center;
		&-txt {
			font-size: rem(14px);
			line-height: rem(16px);
			color: #fff;
			margin: rem(12px) auto;
			width: 140px;
			text-align: center;
		}
		@include media-breakpoint-down(md) {
			&-txt {
				margin-left: 0;
				margin-right: 0;
				width: 140px;
				text-align: left;
			}
			&-img {
				width: 80px;
			}
		}
		@include media-breakpoint-up(md) {
			flex-direction: column;
			justify-content: space-around;
			padding-bottom: rem(16px);
			padding-top: rem(16px);
		}
	}
	&-events {
		@include mavenpro;
		padding-bottom: rem(16px);
		padding-top: rem(16px);
		padding-right: rem(40px);
		padding-left: rem(36px);
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		font-size: rem(14px);
		line-height: rem(16px);
		background-color: #6ac6f7;
		color: #fff;
		position: relative;
		p {
			line-height: 1.3;
		}
		&:after {
			content: '\f107';
			font-size: rem(16px);
			font-family: 'fontello';
			font-style: normal;
			font-weight: normal;
			speak: never;
			background-color: #294264;
			display: block;
			text-decoration: inherit;
			width: rem(36px);
			height: rem(36px);
			text-align: center;
			font-variant: normal;
			text-transform: none;
			line-height: rem(36px);
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			border-bottom-left-radius: rem(8px);
			border-bottom-right-radius: rem(8px);
			position: absolute;
			right: rem(8px);
			top: 0;
		}
		&-title {
			@include mavenpro;
			@include media-breakpoint-down(md) {
				text-align: center;
				font-size: rem(20px);
			}
		}
	}
}

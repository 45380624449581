.link{
	max-width: rem(170px);
	text-align: center;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding: rem(16px);
	img {
		max-width: 100%;
	}
}
.footer {
	background-color: #fff;
	@include mavenpro;
	&-container {
		padding-bottom: rem(42px);
		padding-top: rem(42px);
	}
	&-featured {
		display: flex;
		@include media-breakpoint-down(md) {
			flex-direction: column;
			max-width: 200px;
			text-align: center;
			margin-left: auto;
			margin-right: auto;
		}
		h3 {
			@include mavenpro_bold;
			a{
				font-size: rem(20px);
				color: #294264;
			}
		}
		&-content {
			padding: 0 1rem;
			font-size: rem(12px);
			line-height: rem(12px);
			min-width: 160px;
			color: #464646;
		}
	}
	&-item {
		list-style: none;
		padding: 0;
		margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
		&-logo {
			padding: rem(10px);
		}
	}
	&-link {
		width: 90px;
		color: #6a6a6a;
		display: block;
		font-size: rem(11px);
		line-height: rem(14px);
		&:hover{
			color: #212b3f;
			text-decoration: none;
		}
	}
	&-low {
		background-color: #f1f2f6;
		padding-top: rem(12px);
		padding-bottom: rem(12px);
		p {
			margin-bottom: 0;
			margin-top: 0;
			color: #777;
			font-size: rem(12px);
			a {
				color: #294264;
			}
		}
	}
}

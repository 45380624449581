@import '~sass-rem/rem';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$table-border-width: 0;

$pagination-padding-y: 0.5rem !default;
$pagination-padding-x: 0.75rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: #4e4e4e;
$pagination-bg: null;
$pagination-border-width: 0;
$pagination-border-color: null;
$pagination-focus-box-shadow: null;
$pagination-hover-bg: null;
$pagination-hover-border-color: null;
$pagination-active-color: #294264;
$pagination-active-bg: null;
$pagination-active-border-color: null;
$pagination-disabled-bg: null;
$pagination-disabled-border-color: null;

$theme-colors: map-merge(
  $theme-colors,
  (
    'primary': #294264
  )
);

$carousel-indicator-width: rem(8px);
$carousel-indicator-height: rem(8px);
$carousel-indicator-hit-area-height: rem(12px);
$carousel-indicator-spacer: rem(4px);
$carousel-indicator-active-bg: $white;
$carousel-indicator-transition: opacity 0.6s ease;

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
// @import "~bootstrap/scss/code";
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
// @import "~bootstrap/scss/progress";
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
// @import "~bootstrap/scss/spinners";
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';

@import './fontello-432edcaf/css/fontello.css';
@import './transfonter.org-20200727-182423/stylesheet.css';

@mixin mavenpro_bold() {
  font-family: 'Maven Pro';
  font-weight: bolder;
  font-style: normal;
}

@mixin mavenpro_500() {
  font-family: 'Maven Pro';
  font-weight: 500;
  font-style: normal;
}

@mixin mavenpro() {
  font-family: 'Maven Pro';
  font-weight: normal;
  font-style: normal;
}

@mixin breno_500() {
  font-family: 'Breno';
  font-weight: 500;
  font-style: normal;
}

@mixin breno_500_italic() {
  font-family: 'Breno';
  font-weight: 500;
  font-style: italic;
}

@mixin breno_300() {
  font-family: 'Breno';
  font-weight: 300;
  font-style: normal;
}

@mixin breno_900() {
  font-family: 'Breno';
  font-weight: 900;
  font-style: normal;
}

@mixin breno_bold() {
  font-family: 'Breno';
  font-weight: bolder;
  font-style: normal;
}

@mixin breno() {
  font-family: 'Breno';
  font-weight: normal;
  font-style: normal;
}

@import 'navbar';
@import 'pagination';
@import 'footer';
@import 'accordion';
@import 'content';
@import 'timeline';

@import 'home';
@import 'partners';
@import 'contact';
@import 'links';
@import 'us';

.swal-text {
  text-align: center;
}

.main {
  min-height: calc(100vh - 360px);
  padding-top: 1rem;
  @include media-breakpoint-up(lg) {
    padding-top: 1.5rem;
    min-height: calc(100vh - 330px);
  }
}

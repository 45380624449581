.contact {
	textarea {
		resize: none;
		height: rem(200px);
	}
	&-header {
		font-size: rem(14px);
		line-height: rem(15.4px);
		color: #747474;
	}
	&-form {
		margin-bottom: rem(32px);
		.btn {
			width: rem(190px);
			height: rem(40px);
		}
	}
	&-footer {
		color: #294264;
		text-align: center;
		* {
			font-size: rem(14px);
			line-height: rem(15.4px);
		}
		@include media-breakpoint-up(md) {
			text-align: left;
		}
	}
	&-address {
		color: #777777;
		a {
			color: #434343;
		}
	}
}

.timeline{
	position: relative;
	> li{
		counter-increment: item;
		margin-left: 0px;
		min-height: rem(72px);
		position: relative;
		background-color: white;
		list-style: none;
		padding: rem(12px) 0;
		color: #294264;
		/*Line*/
		&::before{
			content:'';
			position: absolute;
			width: rem(1px);
			background-color: #294264;
		    top: rem(14px);
		    height: 100%;
			left: rem(-30.5px);
		}
		/*Circle*/
		&::after{
		    text-align: center;
		    padding-top: rem(4px);
			z-index: 10;
			content: "";
			position: absolute;
			width: rem(20px);
			height: rem(20px);
			border: rem(4px) solid white;
			background-color: #294264;
			border-radius: 50%;
			top: rem(14px);
			left: rem(-40px);
		}

		&.future::before,
		&.future::after{
			background-color: #6ac6f7;
		}

		&:nth-last-child(1)::before{
			width: 0px;
		}

		strong{
			font-size: rem(16px);
			line-height: rem(12px);
			@include breno_bold;
		}
		h4 {
			font-size: rem(18px);
			line-height: rem(19px);
			@include mavenpro;
		}
	}

}
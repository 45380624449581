.partners {
}
.partner {
	border-radius: 8px;
	border: solid 1px #e1e5ed;
	background-color: #fff;
	padding: rem(28px) rem(32px) rem(12px);
	position: relative;
	&:after {
		content: '\f107';
		font-size: rem(16px);
		font-family: 'fontello';
		font-style: normal;
		font-weight: normal;
		speak: never;
		background-color: #6ac6f7;
		color: #fff;
		display: block;
		text-decoration: inherit;
		width: rem(36px);
		height: rem(36px);
		text-align: center;
		font-variant: normal;
		text-transform: none;
		line-height: rem(36px);
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		border-bottom-left-radius: rem(8px);
		border-bottom-right-radius: rem(8px);
		position: absolute;
		right: rem(20px);
		top: 0;
	}
	&-header {
		margin-bottom: rem(24px);
		h4 {
			@include mavenpro_bold;
			font-size: rem(15px);
			line-height: rem(15px);
			color: #294264;
			margin-bottom: rem(4px);
		}
		strong {
			color: #6ac6f7;
		}
	}
	&-body {
		border-bottom: solid 1px #e1e5ed;
		padding-bottom: rem(24px);
		margin-bottom: rem(8px);
		font-size: rem(14px);
		line-height: rem(20px);
		color: #747474;
	}
	&-footer {
		@include mavenpro;
		* {
			font-size: rem(14px);
			color: #747474;
		}
	}
}
